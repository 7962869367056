import axios from "axios";

// export const SERVER_URL = "http://localhost:4000";
export const SERVER_URL = "https://game-shop.lens-ecom.store";

const initializeApp = () => {
  // Setting base URL for all API request via axios


  axios.defaults.baseURL = SERVER_URL;
  axios.defaults.timeout = 30000;

  // Removing console.log from prod
  // console.log = () => {};

  // init analytics here
};

export default initializeApp;
